import React, { useState } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'

import useIpAddress from '../ipHook'
import phone from '../../assets/images/icon-phone.svg'

const ContactItems = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    a {
      font-size: 1.125em;
      font-weight: 900;
    }

    img {
      height: 16px;
      margin-right: 8px;
    }
  }
`

const ContactUs = () => {
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [status, setStatus] = useState("")
  const [cookies] = useCookies(['hubspotutk']);
  const ipAddress = useIpAddress();

  const disabled = 
    message.trim().length === 0 ||
    email.trim().length === 0

  return (

    <ParallaxProvider>
      <div className="content__container--contact-us">
        <Parallax y={[-12, 12]} tagOuter="section">
          <section id="contact-us">
            <div className="wrapper">
              <Parallax className="custom-class" y={[24, -8]}>
                <div className="popbox">
                  <h2>Contact us</h2>
                  <h4>Send us a message, we're here to help.</h4>
                  <div className="form">
                    <form onSubmit={event => event.preventDefault()}>
                      <label htmlFor="message">Your message</label>
                      <textarea
                        id="message"
                        name="message"
                        rows="5"
                        placeholder="What can we help with?"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />

                      <div style={{ display: 'flex', }}>
                        <div style={{ flex: '1', marginRight: '0.75em' }}>
                          <label htmlFor="name">Your name</label>
                          <input
                            id="name"
                            name="name"
                            placeholder="Name"
                            style={{ width: '100%' }}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div style={{ flex: '1' }}>
                          <label htmlFor="email">Your email</label>
                          <input
                            id="email"
                            name="email"
                            placeholder="Email"
                            style={{ width: '100%' }}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <ContactItems>
                        <button
                          type="submit"
                          disabled={disabled}
                          className={`salmon-button ${disabled && 'disabled'}`}
                          onClick={() => {
                            setStatus("")
                            fetch(process.env.GATSBY_CONTACT_US_URL, {
                              method: 'POST',
                              headers: { 'Content-Type': 'application/json' },
                              body: JSON.stringify({
                                fields: [
                                  { name: 'email', value: email },
                                  { name: 'message', value: message },
                                  { name: 'name', value: name }
                                ],
                                context: {
                                  hutk: cookies.hubspotutk,
                                  ipAddress,
                                  pageName: "Home Page Contact Us Form"
                                }
                              }),
                            })
                              .then(response => response.json())
                              .then(response => {
                                if (response.status === "error") {
                                  setStatus("There was a problem with your submission")
                                  console.error(response)
                                } else {
                                  setStatus("Thanks for your message! We'll be in contact soon.")
                                  setMessage("")
                                  setEmail("")
                                  setName("")
                                }
                              })
                              .catch(error => {
                                setStatus("There was a problem with your submission")
                                console.error(error)
                              });
                          }}
                        >
                          Send message
                        </button>
                        <div>
                          <img src={phone} alt="phone icon" />
                          <a href="tel:+13056448464">(305) 644-8464</a>
                        </div>
                      </ContactItems>
                      <div className="status">
                        {status}
                      </div>
                    </form>
                  </div>
                </div>
              </Parallax>
            </div>
          </section>
          <div className="credit__container left">
            <small className="dark-gray">Forma5 Timber Desk - photo courtesy of Forma5</small>
          </div>
        </Parallax>
      </div>
    </ParallaxProvider>








  )
}




export default ContactUs
