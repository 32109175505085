import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

import Typeform from '../Typeform'
import step1 from '../../assets/images/how-we-work-step1.svg'
import step2 from '../../assets/images/how-we-work-step2.svg'
import step3 from '../../assets/images/how-we-work-step3.svg'

const HowWeWork = () => (
  <ParallaxProvider>
    <div className="content__container--howwework">

        <section id="how-we-work">
          <div className="header">
            <h3 className="text-center">How we work</h3>
            <p className="text-center">We just have a couple of quick questions to get started.</p>
          </div>
          <Parallax x={[-12, 0]}>
            <div className="horizontal">
              <img src={step1} alt="" />
              <div>
                <h4>Step 1</h4>
                <p>Fill out our quick questionnaire to give us an idea of what you’re looking for.</p>
                <Typeform form="Aodb1N" title="Fill it out" />
              </div>
            </div>
          </Parallax>
          <Parallax x={[12, 0]}>
          <div className="horizontal reverse">
            <img src={step2} alt="" />
            <div>
              <h4>Step 2</h4>
              <p>B.House will be in touch with bespoke options based on your criteria.</p>
            </div>
          </div>
          </Parallax>
          <Parallax x={[-12, 0]}>
          <div className="horizontal">
            <img src={step3} alt="" />
            <div>
              <h4>Step 3</h4>
              <p>We arrive on site and make your new space move in ready.</p>
              <Typeform form="Aodb1N" />
            </div>
          </div>
          </Parallax>
        </section>

    </div>
  </ParallaxProvider>
)

export default HowWeWork
