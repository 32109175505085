import React from 'react'
import Typeform from '../Typeform'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'


const ProjectManagement = () => (
  <ParallaxProvider>
    <div className="content__container--art-advisory">
      <section id="art-advisory">
        <div className="wrapper">
        <Parallax y={[24, -6]}>
          <div className="popbox">
            <h2>We've got you covered</h2>
            <h5>Take the hassle out of setting up your new office space! If you are leasing a pre-built or Landlord built space B.House will be your partner delivering a turnkey environment for your team. Our designers will thoughtfully source product tailored to your style and budget. We will be your boots on the ground managing other vendor negotiations, selection and coordination to art, and biophelia++.</h5>
            <Typeform form="Aodb1N" />
          </div>
        </Parallax>
        </div>
      </section>
    </div>
  </ParallaxProvider>
)

export default ProjectManagement