import React, { useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

import forma5 from '../../assets/images/logo-forma5.png'
import hightower from '../../assets/images/logo-hightower.svg'
import bludot from '../../assets/images/logo-bludot.svg'
import dwrLogo from '../../assets/images/dwr-logo.svg'
import { sortedBrands, sortedAllBrands } from '../../utils/sortBrands';

const PreferredBrands = () => {
  const [seeAll, setSeeAll] = useState(false);

  const handleClick = () => {
    setSeeAll(!seeAll)
  }
  
  return (
    <ParallaxProvider>
      <div className="content__container--pb" id="preferred-brands-container">
        <Parallax y={[-8, 12]} tagOuter="section">
        <h2 className="text-center" style={{marginBottom: '2rem'}}>We love our preferred brands.</h2>
          <section id="preferred-brands">
            <div className={!seeAll ? 'wrapper' : ''}>
              <Parallax y={[-10, 8]}>
                <div className="popbox">

                  <div className="logos">
                    <a href="https://www.forma5.com/" rel="noopener noreferrer" target="_blank"><img src={forma5} alt="Forma5 logo" /></a>
                    <a href="https://hightoweraccess.com/" rel="noopener noreferrer" target="_blank"><img src={hightower} alt="Hightower logo" /></a>
                    <a href="https://www.dwr.com/" rel="noopener noreferrer" target="_blank"><img src={dwrLogo} className="large" alt="Design within Reach logo" /></a>
                    <a href="https://www.bludot.com/" rel="noopener noreferrer" target="_blank"><img src={bludot} alt="BluDot logo" /></a>
                  </div>
                  {!seeAll && (
                    <>
                      <ul>
                        {sortedBrands().map( (brand) => (
                          <li key={brand.name}>
                            <a 
                              href={brand.url} 
                              rel="noopener noreferrer" 
                              target="_blank">{brand.name}
                            </a> 
                          </li>
                          ))}
                      </ul>
                    <div className="see-all">
                      <span role="button" tabIndex={0} onKeyPress={handleClick} onClick={handleClick} >See all brands [<i className="fa fa-sort-down" />]</span>
                    </div>
                    </>
                  )}
                  {seeAll && (
                    <>
                    <ul>
                      {sortedAllBrands().map( brand => (
                        <li key={brand.name}>
                          <a 
                            href={brand.url} 
                            rel="noopener noreferrer" 
                            target="_blank">{brand.name}
                          </a> 
                        </li>
                        ))}
                    </ul>
                  <div className="see-all">
                    <span role="button" tabIndex={0} onKeyPress={handleClick} onClick={handleClick} >See less [<i className="fa fa-sort-up" />]</span>
                  </div>
                  </>
                  )}
                </div>
              </Parallax>
            </div>
          </section>
          <div className="credit__container right">
            <small className="dark-gray">Trendway wall - photo courtesy of Trendway</small>
          </div>
        </Parallax>
      </div>
    </ParallaxProvider>
  )
}
export default PreferredBrands;
