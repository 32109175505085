import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import Main from '../components/Main'
import Footer from '../components/Footer'

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <Nav />
        <Main />
        <Footer />
      </Layout>
    )
  }
}

export default Index
