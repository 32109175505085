import brandMappings from '../constants/brands.json';

export const sortedBrands = () => {
  return brandMappings.sort(() => 0.5 - Math.random())
    .slice(0, 31)
    .sort((a, b) => {
      const aName = a.name.toUpperCase()
      const bName = b.name.toUpperCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    })
}

export const sortedAllBrands = () => {
  return brandMappings
    .sort((a, b) => {
      const aName = a.name.toUpperCase()
      const bName = b.name.toUpperCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    })
}