import React, { useState } from 'react'
import Nav from './Nav'
import packageJson from '../../package.json'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'

import useIpAddress from './ipHook'
import phone from '../assets/images/icon-phone.svg'
import pin from '../assets/images/icon-pin.svg'


const InfoItems = styled.div`
  margin: 2rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0.25rem 0;
    a {
      font-size: 1.125em;
      font-weight: 900;
    }
    img {
      height: 24px;
      padding: 4px;
    }
  }
  span {
    font-size:14px;
  }
  @media (max-width: 480px) {
    margin: 2rem 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      font-size:12px;
    }
  }
`

const Footer = () => {
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("")
  const [cookies] = useCookies(['hubspotutk']);
  const ipAddress = useIpAddress();

  const disabled = email.trim().length === 0;

  return (
    <footer id="footer">

      <div className="form">
        <h5>Sign up for updates</h5>
        <form onSubmit={event => event.preventDefault()}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            placeholder="Your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button
            disabled={disabled}
            className={`salmon-button ${disabled && 'disabled'}`}
            onClick={() => {
              setStatus("")
              fetch(process.env.GATSBY_EMAIL_CAPTURE_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  fields: [
                    { name: 'email', value: email }
                  ],
                  context: {
                    hutk: cookies.hubspotutk,
                    ipAddress,
                    pageName: "Home Page Footer"
                  }
                }),
              })
                .then(response => response.json())
                .then(response => {
                  if (response.status === "error") {
                    setStatus("There was a problem with your submission")
                    console.error(response)
                  } else {
                    setStatus("Thanks for your email address!")
                    setEmail("")
                  }
                })
                .catch(error => {
                  setStatus("There was a problem with your submission")
                  console.error(error)
                });
            }}
          >
            Get updates
          </button>
          <div className="status">
            {status}
          </div>
        </form>
      </div>
      <Nav className="" location="bottom" />
      <InfoItems className="info-items">
        <div>
          <img src={phone} alt="phone icon" /> <a href="tel:+13056448464">(305) 644-8464</a>
        </div>
        <div>
          <img src={pin} alt="map pin icon" /> <span>255 Giralda Avenue, 5th floor, Coral Gables, FL 33134</span>
        </div>
      </InfoItems>
      <p className="copyright">
        &copy; {(new Date()).getFullYear()} B.House<br />
        <span>v{packageJson.version + '+' + process.env.GATSBY_GIT_HASH}</span>
      </p>
    </footer>
  )
}

export default Footer
