import React from 'react'
import Typeform from '../Typeform'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'


const Hero = () => (
  <ParallaxProvider>
    <div className="content__container--hero">
      <section id="hero">
        <div className="wrapper">
        <Parallax y={[24, -6]}>
          <div className="popbox">
            <h1>Let's build your dream workspace.</h1>
            <h4>B.House is a commercial furniture and product company committed to delivering smart furniture solutions for creatively minded companies.</h4>
            <Typeform form="Aodb1N"/>
          </div>
        </Parallax>
        </div>
      </section>
      <div className="credit__container right">
        <small className="dark-gray">BluDot Stash desk & Clutch chair - photo courtesy of BluDot</small>
      </div>
    </div>
  </ParallaxProvider>
)

export default Hero
