import { useState, useEffect } from 'react'

const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState("")

  useEffect(() => {
    const getIp = () => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIpAddress(data.ip))
        .catch(console.error)
    }

    getIp()
  }, [])

  return ipAddress
}

export default useIpAddress
