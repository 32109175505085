import React, { useState, useEffect } from 'react';
import * as typeformEmbed from '@typeform/embed'

const Questionnaire = ({form, title = "Let's get started "}) => {
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    setPopup(typeformEmbed.makePopup(
      `https://mfernandezedge.typeform.com/to/${form}`,
      {
        mode: 'popup',
        autoClose: 3000,
        hideHeaders: true,
        hideFooters: true,
        onSubmit: function() {
          console.log('Yo!')
        }
      }
    ))
  }, [form]);

  return (
    <div>
      <button 
        className="salmon-button" 
        onClick={() => { popup.open() }}
      >
        {title}
      </button>
    </div>
  )
}

export default Questionnaire;
