import React from 'react'

import Hero from './Hero'
import AboutUs from './AboutUs'
import HowWeWork from './HowWeWork'
import BrandGallery from './BrandGallery'
import PreferredBrands from './PreferredBrands'
import Verticals from './Verticals'
import ContactUs from './ContactUs'
import ProjectManagement from './ProjectManagement'

const Main = () => (
  <div id="main">
    <Hero />
    <AboutUs />
    <PreferredBrands />
    <BrandGallery />
    <HowWeWork />
    <ProjectManagement />
    <Verticals />
    <ContactUs />
  </div>
)

export default Main
