import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'


const AboutUs = () => (
  <ParallaxProvider>
    <div className="content__container--about-us">
      <Parallax y={[-12, 12]} tagOuter="section">
        <section id="about-us">
          <div className="wrapper">
            <Parallax className="custom-class" y={[-8, 8]}>
            <div className="popbox">
              <h2>Furnishing modern productivity</h2>
              <h5>The B.House team has been in the commercial furniture sector collectively for 50+ years, it is not our first rodeo. We aim to simplify the whole process for our clients, whether it be an interior architect or business owner. The B.House brand agnostic portfolio encompasses a variety of eclectic manufacturers that will enhance the design vision for any commercial environment.</h5>
            </div>
            </Parallax>
          </div>
        </section>
        <div className="credit__container left">
          <small className="dark-gray">Dot.Pro task chair and Let's Work desk system by Forma5 - photo courtesy of Forma5</small>
        </div>
      </Parallax>
    </div>
  </ParallaxProvider>
)

export default AboutUs
