import React from 'react'
import Flickity from 'react-flickity-component'

import slide1 from '../../assets/images/slide-bludot4.jpg'
import slide2 from '../../assets/images/slide-sancal-obras-restaurante-sight-coffe-and-dine.jpg'
import slide3 from '../../assets/images/let-sit.jpg'
import slide4 from '../../assets/images/dhive-cielo.jpg'
import slide5 from '../../assets/images/stylex.jpg'
import slide6 from '../../assets/images/forma5.webp'
import slide7 from '../../assets/images/global-electronic-arts-cafeteria.jpg'
import slide9 from '../../assets/images/fourlikes.webp'
import slide10 from '../../assets/images/pair-office-solution.jpg'
import slide11 from '../../assets/images/plant.webp'
import slide12 from '../../assets/images/tucurinca-trey-rocking-chair-in-rainbow.webp'

const GalleryItem = ({ img, text }) => (
  <div className="img">
    <img src={img} alt={text} />
    <div className="text-center">
      <small className="dark-gray">{text}</small>
    </div>
  </div>
)

const BrandGallery = () => {
  return (
    <div className="content__container--brand-gallery">
      <section id="brand-gallery">
        <div className="header">
          <h2 className="text-center">Brand Gallery</h2>
        </div>

        <Flickity
          className={'carousel'}
          options={{ wrapAround: true, contain: true, groupCells: true }}
        >
          <GalleryItem img={slide1} text="BluDot Chair and Lamp - photo courtesy of BluDot" />
          <GalleryItem img={slide2} text="Sancal Silla 40 20’s Chair – photo courtesy of Sancal" />
          <GalleryItem img={slide3} text="Forma5 Let’s family soft seating – photo courtesy of Forma5" />
          <GalleryItem img={slide4} text="dHive wall system – photo courtesy of dHive" />
          <GalleryItem img={slide5} text="Stylex free address system – photo courtesy Stylex" />
          <GalleryItem img={slide6} text="Forma5 Bow sofa and armchairs – photo courtesy of Forma5" />
          <GalleryItem img={slide7} text="Global bakhita seating – photo courtesy of Global" />
          <GalleryItem img={slide9} text="Hightower FourLikes seating system – photo courtesy of Hightower" />
          <GalleryItem img={slide10} text="Pair Swing system shown with Belay desk – photo courtesy of Pair" />
          <GalleryItem img={slide11} text="SilenSpace meeting room – photo courtesy of SilenSpace" />
          <GalleryItem img={slide12} text="Tucurinca Trey Rocking Chair – photo courtesy of Tucurinca" />
        </Flickity>
      </section>
    </div>
  )
}

export default BrandGallery
