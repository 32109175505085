import React from 'react'

import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

const Verticals = () => (
  <ParallaxProvider>
    <div className="content__container--verticals">
      <Parallax y={[-12, 12]} tagOuter="section">
        <section id="verticals">
          <div className="wrapper">
            <Parallax className="custom-class" y={[-8, 8]}>
              <div className="popbox">
                <h2>Stay nimble</h2>
                <h5>We know that furnishing your work enviroment can be costly and paying upfront can be a drain on your business. We offer furniture rental and  financing options that are flexible, quick, and can reduce your organizations tax liability, a win win!</h5>
              </div>
            </Parallax>
          </div>
        </section>
      </Parallax>
    </div>
  </ParallaxProvider>
)

export default Verticals
